import { defineStore } from 'pinia'
import type { AvailableRoles } from '~/repository/modules/roles'

export const useRolesStore = defineStore('roles', () => {
  const { $api } = useNuxtApp()

  const availableRoles = ref<AvailableRoles>([])
  const availableDisplayRoles = ref<AvailableRoles>([])

  const loading = ref<boolean>(false)

  const loadAvailableDisplayRoles = async () => {
    const { data } = await $api.roles.availableRoles(true)
    availableDisplayRoles.value = (data as unknown as AvailableRoles).sort((a, b) => a.label.localeCompare(b.label))
  }

  const loadAvailableRoles = async () => {
    const { data } = await $api.roles.availableRoles()
    availableRoles.value = (data as unknown as AvailableRoles).sort((a, b) => a.label.localeCompare(b.label))
  }

  return {
    loading,
    availableRoles,
    availableDisplayRoles,

    loadAvailableRoles,
    loadAvailableDisplayRoles
  }
})
